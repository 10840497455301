<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main" v-if="dateType==1">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
			<div class="filter-main" v-else-if="dateType == 4">{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<div class="filter-main" v-else>{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}~{{ parseInt(endTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 1}" @click="handleChangeTime(1)">{{$t('sycm.actions.realTime')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 2}" @click="handleChangeTime(2)">7{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">30{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 4}" @click="handleDatePicker(4)">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:default-value="new Date()"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								@change="changeDatePicker($event,4)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 5}" @click="handleDatePicker(5)">{{$t('sycm.actions.week')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="weekData"
								type="week"
								ref="weekPicker"
								format="WW"
								:default-value="new Date()"
								:placeholder="$t('sycm.placeholder.week')"
								:picker-options="pickerWeekOptions"
								@change="changeDatePicker($event,5)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 6}" @click="handleDatePicker(6)">{{$t('sycm.actions.month')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="monthData"
								type="month"
								ref="monthPicker"
								:default-value="new Date()"
								:placeholder="$t('sycm.placeholder.month')"
								:picker-options="pickerMonthOptions"
								@change="changeDatePicker($event,6)">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.cate.indicatorMonitor')}}</div>
				<div class="barbox"></div>
			</div>
			<div class="section-main">
				<div class="sycm-panel">
					<div class="item">
						<div class="item-main">
							<div class="name">{{$t('sycm.cate.goodsSales')}}</div>
							<ul class="item-box">
								<li><div class="li-label">{{$t('sycm.common.payMoney')}}</div><div class="li-block"><span class="number">{{movablePinData.curr_value | priceFilter()}}</span></div></li>
								<li>
									<div class="li-label">{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</div>
									<div class="li-block" v-if="movablePinData.rate > 0" :class="movablePinData.curr_value > movablePinData.comp_value ? 'red' : 'green'">
										<span>{{movablePinData.rate}}%</span>
										<i :class="movablePinData.curr_value > movablePinData.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i>
									</div>
									<div class="li-block" v-else>-</div>
								</li>
							</ul>
						</div>
						<div class="item-chart">
							<flow-chart id="movablePinChart" v-if="movablePinChart" :x-data="movablePinChart.xData" :s-data="movablePinChart.sData" :max-axis="movablePinChart.max.toString()" height="100%" width="100%" />
						</div>
					</div>
					<div class="item active">
						<div class="item-main">
							<div class="name">{{$t('sycm.cate.goodsCollectAddBuy')}}</div>
							<ul class="item-box">
								<li><div class="li-label">{{$t('sycm.cate.goodsAddBuy')}}</div><div class="li-block"><span class="number">{{collectData.curr_value}}</span></div></li>
								<li>
									<div class="li-label">{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</div>
									<div class="li-block" v-if="collectData.rate > 0" :class="collectData.curr_value > collectData.comp_value ? 'red' : 'green'">
										<span>{{collectData.rate}}%</span>
										<i :class="collectData.curr_value > collectData.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i>
									</div>
									<div class="li-block" v-else>-</div>
								</li>
							</ul>
						</div>
						<div class="item-chart">
							<flow-chart id="collectChart" v-if="collectChart" :x-data="collectChart.xData" :s-data="collectChart.sData" :max-axis="collectChart.max.toString()" height="100%" width="100%" />
						</div>
					</div>
					<div class="item">
						<div class="item-main">
							<div class="name">{{$t('sycm.cate.goodsVisit')}}</div>
							<ul class="item-box">
								<li><div class="li-label">{{$t('sycm.cate.goodsVisitors')}}</div><div class="li-block"><span class="number">{{visitorsData.curr_value}}</span></div></li>
								<li>
									<div class="li-label">{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</div>
									<div class="li-block" v-if="visitorsData.rate > 0" :class="visitorsData.curr_value > visitorsData.comp_value ? 'red' : 'green'">
										<span>{{visitorsData.rate}}%</span>
										<i :class="visitorsData.curr_value > visitorsData.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i>
									</div>
									<div class="li-block" v-else>-</div>
								</li>
							</ul>
						</div>
						<div class="item-chart">
							<flow-chart id="visitorsChart" v-if="visitorsChart" :x-data="visitorsChart.xData" :s-data="visitorsChart.sData" :max-axis="visitorsChart.max.toString()" height="100%" width="100%" />
						</div>
					</div>
				</div>
				<div class="sycm-multiple">
					<div class="item" v-if="overviewData.onto_carts" :class="{active: chartType == overviewData.onto_carts.type}" @click="getCateChart(overviewData.onto_carts.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.cate.goodsAddBuy')}}</span>
							</div>
							<div class="number">{{overviewData.onto_carts.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</span>
							<span v-if="overviewData.onto_carts.rate > 0" :class="overviewData.onto_carts.curr_value > overviewData.onto_carts.comp_value ? 'red' : 'green'">{{overviewData.onto_carts.rate}}% <i :class="overviewData.onto_carts.curr_value > overviewData.onto_carts.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.into_carts_persons" :class="{active: chartType == overviewData.into_carts_persons.type}" @click="getCateChart(overviewData.into_carts_persons.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.cate.goodsAddBuyPer')}}</span>
							</div>
							<div class="number">{{overviewData.into_carts_persons.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</span>
							<span v-if="overviewData.into_carts_persons.rate > 0" :class="overviewData.into_carts_persons.curr_value > overviewData.into_carts_persons.comp_value ? 'red' : 'green'">{{overviewData.into_carts_persons.rate}}% <i :class="overviewData.into_carts_persons.curr_value > overviewData.into_carts_persons.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.collect_persons" :class="{active: chartType == overviewData.collect_persons.type}" @click="getCateChart(overviewData.collect_persons.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.cate.goodsCollectPer')}}</span>
							</div>
							<div class="number">{{overviewData.collect_persons.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</span>
							<span v-if="overviewData.collect_persons.rate > 0" :class="overviewData.collect_persons.curr_value > overviewData.collect_persons.comp_value ? 'red' : 'green'">{{overviewData.collect_persons.rate}}% <i :class="overviewData.collect_persons.curr_value > overviewData.collect_persons.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.visitor_into_cart_rate" :class="{active: chartType == overviewData.visitor_into_cart_rate.type}" @click="getCateChart(overviewData.visitor_into_cart_rate.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.cate.visitPlusRate')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.cate.visitPlusRate')+'”'}}</p>
										<p>{{$t('sycm.popover.visitPlusRate1')}}</p>
									</div>
									<div class="popover-main">
										<div class="popover-formula">
											<div class="formula-title">{{$t('sycm.popover.formula')}}</div>
											<div class="formula-item">
												<div class="item-label">{{$t('sycm.cate.visitPlusRate')}} = </div>
												<div class="item-block">
													<div class="text">{{$t('sycm.cate.goodsAddBuyPer')}}</div>
													<div class="fit"></div>
													<div class="text">{{$t('sycm.cate.goodsVisitors')}}</div>
												</div>
											</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.visitor_into_cart_rate.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</span>
							<span v-if="overviewData.visitor_into_cart_rate.rate > 0" :class="overviewData.visitor_into_cart_rate.curr_value > overviewData.visitor_into_cart_rate.comp_value ? 'red' : 'green'">{{overviewData.visitor_into_cart_rate.rate}}% <i :class="overviewData.visitor_into_cart_rate.curr_value > overviewData.visitor_into_cart_rate.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.visitor_collect_rate" :class="{active: chartType == overviewData.visitor_collect_rate.type}" @click="getCateChart(overviewData.visitor_collect_rate.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.cate.accessCollectRate')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-text">{{$t('sycm.popover.accessCollectRate1')}}</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.visitor_collect_rate.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.cateEarlier'))}}</span>
							<span v-if="overviewData.visitor_collect_rate.rate > 0" :class="overviewData.visitor_collect_rate.curr_value > overviewData.visitor_collect_rate.comp_value ? 'red' : 'green'">{{overviewData.visitor_collect_rate.rate}}% <i :class="overviewData.visitor_collect_rate.curr_value > overviewData.visitor_collect_rate.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
				</div>
				<div class="sycm-chart">
					<sycm-chart id="chartOption" v-if="chartOption" :x-data="chartOption.xData" :s-data="chartOption.sData" :max-axis="chartOption.max.toString()" :x-interval="xInterval" legend-left="0" height="100%" width="100%" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import flowChart from '@/components/echart/flowChart'
import sycmChart from '@/components/echart/ipollChart'
import { fetchGoodsMovablePin, fetchGoodsCollectAddBuy, fetchGoodsVisitors, fetchCateOverview, fetchCateChart } from "@/api/sycm"
export default {
	components: { flowChart, sycmChart },
	data() {
		return {
			dateType: 1,
			dayData: '',
			weekData: '',
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			pickerWeekOptions: {
				disabledDate(time) {
					const today = new Date()
					const day = today.getDay()
					today.setDate(today.getDate()-day)
					const end_time = today.getTime()
					today.setDate(today.getDate()-84)
					const start_time = today.getTime()
					return time.getTime() >end_time || time.getTime() < start_time
				},
				firstDayOfWeek: 1
			},
			pickerMonthOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-1)
					const end_time = today.getTime()
					today.setMonth(today.getMonth()-11)
					const start_time = today.getTime()
					return time.getTime() > end_time || time.getTime() < start_time
				}
			},
			startTime: '',
			endTime: '',
			movablePinData: {},
			movablePinChart: null,
			collectData: {},
			collectChart: null,
			visitorsData: {},
			visitorsChart: null,
			overviewData: {},
			chartType: 1,
			chartOption: null,
			xInterval: 1
		}
	},
	created () {
		this.handleChangeTime()
	},
	methods: {
		async getData() {
			const loading = this.$loading()
			this.movablePinChart = null
			this.collectChart = null
			this.visitorsChart = null
			try {
				const params = {
					time_from: parseInt(this.startTime / 1000),
					time_to: parseInt(this.endTime / 1000),
					date_type: this.dateType
				}
				const movablePin = await fetchGoodsMovablePin(params)
				this.movablePinData = movablePin.data
				this.movablePinChart = this.getChartData(movablePin.data.list || [])
				const collect = await fetchGoodsCollectAddBuy(params)
				this.collectData = collect.data
				this.collectChart = this.getChartData(collect.data.list || [])
				const visitors = await fetchGoodsVisitors(params)
				this.visitorsData = visitors.data
				this.visitorsChart = this.getChartData(visitors.data.list || [])
				const overview = await fetchCateOverview(params)
				this.overviewData = overview.data
				this.getCateChart()
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		handleChangeTime(val) {
			this.dateType = val ? val : 1
			let today = new Date()
			switch(this.dateType) {
				case 1:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = Date.now()
					break;
				case 2:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-7).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 3:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleDatePicker(type) {
			switch(type) {
				case 4:
					this.$refs.dayPicker.focus()
					break;
				case 5:
					this.$refs.weekPicker.focus()
					break;
				case 6:
					this.$refs.monthPicker.focus()
					break;
			}
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			switch(type) {
				case 4:
					this.monthData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
					break;
				case 5:
					this.dayData = ''
					this.monthData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+6).getTime() - 1000
					break;
				case 6:
					this.dayData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth()+1,1).getTime() - 1000
					break;
			}
			this.getData()
		},
		getChartData(data) {
			const xData = []
			const todayData = []
			const yesterdayData = []
			var max = 0
			data.forEach(item=> {
				xData.push(item.x_values+":00")
				todayData.push(item.values)
				yesterdayData.push(item.comp_values)
				if(parseFloat(item.values) > max) {
					max = parseFloat(item.values)
				}
				if(parseFloat(item.comp_values) > max && this.dateType === 1) {
					max = parseFloat(item.comp_values)
				}
			})
			var sData = []
			if(this.dateType !== 1) {
				sData = [{
					data: todayData,
					type: 'line',
					smooth: true,
					areaStyle: {},
					z: 4,
					itemStyle: {
						color: '#2062e6'
					},
					symbol: 'none'
				}]
			} else {
				sData = [ {
					data: yesterdayData,
					type: 'line',
					smooth: true,
					areaStyle: {},
					itemStyle: {
						color: '#cecece'
					},
					symbol: 'none'
				}, {
					data: todayData,
					type: 'line',
					smooth: true,
					areaStyle: {},
					z: 4,
					itemStyle: {
						color: '#2062e6'
					},
					symbol: 'none'
				}]
			}
			const option = {
				max: max ? max : 4,
				xData: xData,
				sData: sData
			}
			return option
		},
		async getCateChart(type) {
			this.chartType = type || this.chartType
			this.chartOption = null
			const params = {
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000),
				date_type: this.dateType,
				type: this.chartType
			}
			const { data } = await fetchCateChart(params)
			
			const xData = []
			let sData = []
			const todayData = []
			const yesterdayData = []
			const d = new Date()
			const hours = d.getHours()
			var max = 0
			data.list.forEach(item=> {
				if(item.x_values && item.x_values.indexOf('-')<0 && this.dateType !== 5) {
					xData.push(item.x_values+":00")
					if( hours >= parseInt(item.x_values)) {
						todayData.push(item.values)
					}
				} else {
					xData.push(item.x_values)
					todayData.push(item.values)
				}
				yesterdayData.push(item.comp_values)
				if(parseFloat(item.values) > max) {
					max = parseFloat(item.values)
				}
				if(parseFloat(item.comp_values) > max && this.dateType === 1) {
					max = parseFloat(item.comp_values)
				}
			})
			
			const nameOption = this.$t('sycm.options.catePanel')
			if(this.dateType == 1) {
				sData = [{
					name: this.$t('sycm.common.today'),
					data: todayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#2062e6'
					}
				}, {
					name: this.$t('sycm.common.yesterday'),
					data: yesterdayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					z: 4,
					itemStyle: {
						color: '#f3d024'
					}
				}]
			}else {
				sData = [{
					name: nameOption[this.chartType],
					data: todayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#2062e6'
					}
				}]
			}
			if (this.dateType == 6) {
				this.xInterval = 0
			} else {
				this.xInterval = 1
			}
			this.chartOption = {
				max: max ? max : 4,
				xData: xData,
				sData: sData
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;
		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;
			.title {
				font-size: 16px;
				font-weight: bold;
			}
			.barbox {
				span {
					margin-right: 10px;
				}
			}
		}
		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}
	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}
		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-panel {
		display: flex;
		margin-top: 30px;
		margin-bottom: 15px;
		.item {
			flex: 1;
			position: relative;
			overflow: hidden;
			padding: 20px 8px;
			border: 1px solid #dbe5ed;
			border-right: none;
			cursor: pointer;
			.item-main {
				padding: 0 30px;
				.name {
					font-size: 16px;
					line-height: 1;
					margin-bottom: 12px;
				}
			}
			.item-box {
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.li-label {
						color: #999999;
					}
					.li-block {
						font-size: 12px;
						line-height: 1.5;
						color: #999999;
						.number {
							color: #333333;
							font-size: 24px;
							line-height: 30px;
							font-weight: 700;
						}
						&.red {
							color: #fe7c24;
						}

						&.green {
							color: #00ba26;
						}

						[class^=el-icon-] {
							margin-left: 6px;
							font-weight: bold;
						}
					}
				}
			}
			.item-chart {
				height: 64px;
				margin: 20px -8px -20px;
			}
			&:last-child {
				border-right: 1px solid #dbe5ed;
			}
			&.active {
				&:before {
					top: 0;
					left: 0;
					display: block;
					content: "";
					width: 100%;
					height: 4px;
					position: absolute;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-multiple {
		display: flex;
		flex-flow: wrap;
		margin-bottom: 20px;
		.item {
			width: 20%;
			flex-shrink: 0;
			padding: 20px 8px;
			border: 1px solid #dbe5ed;
			border-top-color: transparent;
			border-left-color: transparent;
			cursor: pointer;
			font-size: 12px;
			line-height: 1.5;
			box-sizing: border-box;
			.item-main {
				.name {
					font-size: 14px;
					.el-popover__reference {
						color: #999999;
						margin-left: 4px;
						cursor: pointer;
					}
				}
				.number {
					font-size: 24px;
					font-weight: 700;
					margin-bottom: 11px;
				}
			}
			.item-box {
				color: #999999;
				max-width: 150px;
				display: flex;
				justify-content: space-between;
				.red {
					color: #fe7c24;
				}
				.green {
					color: #00ba26;
				}
				[class^=el-icon-] {
					margin-left: 6px;
					font-weight: bold;
				}
			}
			&:nth-child(5n - 4) {
				border-left-color: #dbe5ed;
			}
			&:nth-child(-n + 5) {
				border-top-color: #dbe5ed;
			}
			&:hover {
				border-color: $--sycm-primary;
			}
			&.active {
				border-color: $--sycm-primary;
				outline: 1px solid $--sycm-primary;
			}
		}
	}
	.sycm-chart {
		height: 300px;
	}
}
</style>